import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faStackOverflow,
    faInstagram,
    faReact,
    faHtml5,
    faCss3Alt,
    faJs,
    faNode,
    faPython,
    faJava,
    faAws,
    faSwift
} from "@fortawesome/free-brands-svg-icons";
import { faLaptopCode, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import {
    SiFastapi,
    SiNumpy,
    SiPandas,
    SiScikitlearn,
    SiLinux,
    SiMongodb,
    SiMysql,
    SiMicrosoftazure
} from "react-icons/si";
import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";
import INFO from "../data/user";
import SEO from "../data/seo";
import "./styles/homepage.css";

const Homepage = () => {
    const [stayLogo, setStayLogo] = useState(false);
    const [logoSize, setLogoSize] = useState(80);
    const [oldLogoSize, setOldLogoSize] = useState(80);
    const [showAltLogo, setShowAltLogo] = useState(false);
    const [initialAnimation, setInitialAnimation] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setLogoSize(80);
            setShowAltLogo(true);
        }, 500);
        setTimeout(() => {
            setLogoSize(80);
            setShowAltLogo(false);
            setInitialAnimation(false);
        }, 1500);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            let scroll = Math.round(window.pageYOffset, 2);
            let newLogoSize = 80 - (scroll * 4) / 10;
            if (!initialAnimation && newLogoSize < oldLogoSize) {
                if (newLogoSize > 40) {
                    setLogoSize(newLogoSize);
                    setOldLogoSize(newLogoSize);
                    setStayLogo(false);
                } else {
                    setStayLogo(true);
                }
            } else if (!initialAnimation) {
                setLogoSize(newLogoSize);
                setStayLogo(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [logoSize, oldLogoSize, initialAnimation]);

    const currentSEO = SEO.find((item) => item.page === "home");

    const logoStyle = {
        display: "flex",
        position: stayLogo ? "fixed" : "relative",
        top: stayLogo ? "3vh" : "auto",
        zIndex: 999,
        border: stayLogo ? "2px solid var(--highlight-color)" : "none",
        borderRadius: showAltLogo ? "0%" : stayLogo || initialAnimation ? "50%" : "10%",
        boxShadow: stayLogo ? "0px 8px 20px #000, 0px 4px 15px #fff" : "none",
        background: stayLogo ? "#000" : "transparent",
        transform: stayLogo ? "scale(1) rotate(5deg)" : "none",
        transition: "all 1.5s ease-in-out",
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{INFO.main.title}</title>
                <meta name="description" content={currentSEO.description} />
                <meta name="keywords" content={currentSEO.keywords.join(", ")} />
            </Helmet>
            <div className="page-content">
                <NavBar active="home" />
                <div className="content-wrapper">
                    <div className="homepage-logo-container">
                        <div style={logoStyle}>
                            {showAltLogo ? (
                                <img src="Hi.png" alt="Hi" width={logoSize} style={{ borderRadius: "0%" }} />
                            ) : (
                                <Logo width={logoSize} link={false} customClass={stayLogo ? "enlarged" : ""} />
                            )}
                        </div>
                    </div>
                    <div className="homepage-container">
                        <div className="homepage-first-area">
                            <div className="homepage-first-area-left-side">
                                <div className="title homepage-title-two">{INFO.homepage.title2}</div>
                                <div className="title homepage-title">{INFO.homepage.title}</div>
                                <div className="resume-download">
                                    <a href="/resume.pdf" download className="resume-link">
                                        Checkout My Resume!
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="homepage-socials">
                            <a href={INFO.socials.github} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faGithub} className="homepage-social-icon" />
                            </a>
                            <a href={INFO.socials.stackoverflow} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faStackOverflow} className="homepage-social-icon" />
                            </a>
                            <a href={INFO.socials.instagram} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className="homepage-social-icon" />
                            </a>
                            <a href={`mailto:${INFO.main.email}`} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faMailBulk} className="homepage-social-icon" />
                            </a>
                        </div>

                        <h3 className="skills-heading">SKILLS</h3>
                        <div className="skills-grid">
                            <table className="skills-table">
                                <thead>
                                    <tr>
                                        <th className="skill-header">Frontend</th>
                                        <th className="skill-header">Backend</th>
                                        <th className="skill-header">Mobile Development</th>
                                        <th className="skill-header">Machine Learning</th>
                                        <th className="skill-header">Database</th>
                                        <th className="skill-header">DevOps</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faReact} className="skill-icon" />
                                                <span>React</span>
                                            </div>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faHtml5} className="skill-icon" />
                                                <span>HTML5</span>
                                            </div>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faCss3Alt} className="skill-icon" />
                                                <span>CSS3</span>
                                            </div>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faJs} className="skill-icon" />
                                                <span>JavaScript</span>
                                            </div>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faLaptopCode} className="skill-icon" />
                                                <span>TypeScript</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faNode} className="skill-icon" />
                                                <span>Node.js</span>
                                            </div>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faPython} className="skill-icon" />
                                                <span>Python</span>
                                            </div>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faJava} className="skill-icon" />
                                                <span>Java</span>
                                            </div>
                                            <div className="skill-item">
                                                <SiFastapi className="skill-icon" />
                                                <span>FastAPI</span>
                                            </div>
                                            <div className="skill-item">
                                                <SiLinux className="skill-icon" />
                                                <span>Linux</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faSwift} className="skill-icon" />
                                                <span>Swift</span>
                                            </div>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faReact} className="skill-icon" />
                                                <span>React Native</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="skill-item">
                                                <SiNumpy className="skill-icon" />
                                                <span>NumPy</span>
                                            </div>
                                            <div className="skill-item">
                                                <SiPandas className="skill-icon" />
                                                <span>Pandas</span>
                                            </div>
                                            <div className="skill-item">
                                                <SiScikitlearn className="skill-icon" />
                                                <span>Sklearn</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="skill-item">
                                                <SiMongodb className="skill-icon" />
                                                <span>MongoDB</span>
                                            </div>
                                            <div className="skill-item">
                                                <SiMysql className="skill-icon" />
                                                <span>MySQL</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="skill-item">
                                                <FontAwesomeIcon icon={faAws} className="skill-icon" />
                                                <span>AWS</span>
                                            </div>
                                            <div className="skill-item">
                                                <SiMicrosoftazure className="skill-icon" />
                                                <span>Azure</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h3 className="experience-heading">Experience</h3>
                        <AllProjects />

                        <h3 className="experience-heading">Education</h3>
                        <Works />

                        <div className="homepage-after-title"></div>

                        <div className="page-footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Homepage;
