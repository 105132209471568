import React from "react";
import NavBar from "../components/common/navBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNode, faPython, faJava } from "@fortawesome/free-brands-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import "./styles/skills.css";

const Backend = () => {
	return (

		<><NavBar active="" /><div className="skills-container"></div><div className="skills-container">
			<h1>Backend Skills</h1>
			<div className="skills-icons">
				<FontAwesomeIcon icon={faNode} size="3x" className="fa-icon" />
				<FontAwesomeIcon icon={faPython} size="3x" className="fa-icon" />
				<FontAwesomeIcon icon={faJava} size="3x" className="fa-icon" />
				<FontAwesomeIcon icon={faDatabase} size="3x" className="fa-icon" />
			</div>
			<h2>Relavant Experience</h2>
			<div className="project">
				<h3>SiMLQ</h3>
				<p>Engineered a powerful, scalable backend using Python for process mining software.</p>
				<p><strong>Key Contributions:</strong></p>
				<ul>
					<li>Developed RESTful APIs for efficient data handling and processing.</li>
					<li>Implemented robust security measures to protect sensitive data.</li>
					<li>Optimized database queries to improve application performance.</li>
				</ul>
				<div className="tech-list">
					<span className="tech-item">Python</span>
					<span className="tech-item">Pytest</span>
					<span className="tech-item">SQL</span>
					<span className="tech-item">FastAPI</span>
				</div>
			</div>
			<div className="project">
				<h3>Yu Ride</h3>
				<p>Implemented back-end functionalities using Node.js and MongoDB for a ride-sharing app.</p>
				<p><strong>Key Contributions:</strong></p>
				<ul>
					<li>Developed user authentication and authorization mechanisms.</li>
					<li>Implemented real-time data updates using WebSockets.</li>
					<li>Ensured data integrity and consistency across multiple services.</li>
				</ul>
				<div className="tech-list">
					<span className="tech-item">Node.js</span>
					<span className="tech-item">MongoDB</span>
					<span className="tech-item">Express.js</span>
				</div>
			</div>
			<div className="project">
				<h3>Pink Detect</h3>
				<p>Developed a machine learning model using Python, NumPy, and scikit-learn to predict breast cancer.</p>
				<p><strong>Key Contributions:</strong></p>
				<ul>
					<li>Preprocessed and normalized breast cancer dataset using NumPy for efficient numerical computations.</li>
					<li>Implemented a logistic regression model with scikit-learn to classify tumor malignancy.</li>
					<li>Performed hyperparameter tuning and cross-validation to optimize model accuracy.</li>
					<li>Evaluated model performance using confusion matrix, precision, recall, and ROC-AUC score.</li>
				</ul>
				<div className="tech-list">
					<span className="tech-item">Python</span>
					<span className="tech-item">NumPy</span>
					<span className="tech-item">scikit-learn</span>
				</div>
			</div>

		</div></>
	);
};

export default Backend;
