import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./styles/contactForm.css";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const [errors, setErrors] = useState({}); // State to hold error messages

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear the error message when user starts typing
        if (errors[name]) {
            setErrors({ ...errors, [name]: "" });
        }
    };

    const validateEmail = (email) => {
        // Simple email validation regex
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate form inputs
        let formErrors = {};

        if (!formData.name.trim()) {
            formErrors.name = "NameError: Name field cannot be empty.";
        }

        if (!validateEmail(formData.email)) {
            formErrors.email = `EmailError: Invalid email format at '${formData.email}'`;
        }

        if (!formData.message.trim()) {
            formErrors.message = "MessageError: Message field cannot be empty.";
        }

        // If there are errors, set the error state and don't proceed
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Send email via emailjs if no errors
        emailjs.send(
            "service_qb5xzjl", // Service ID
            "template_1em23nb", // Template ID
            {
                from_name: formData.name, // Map the name to the template variable
                reply_to: formData.email, // Map the email to the template variable
                message: formData.message, // Map the message to the template variable
            },
            "3CfkP43ptN12A0LMN" // Public key
        )
        .then((response) => {
            console.log("SUCCESS!", response.status, response.text);
            alert("Message sent successfully!");
            setFormData({ name: "", email: "", message: "" });
        })
        .catch((err) => {
            console.log("FAILED...", err);
            alert("Failed to send the message. Please try again later.");
        });
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                {errors.name && <div className="error-message">{errors.name}</div>}
            </div>
            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
            </div>
            <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
                {errors.message && <div className="error-message">{errors.message}</div>}
            </div>
            <button type="submit" className="submit-button">Send Message</button>
        </form>
    );
};

export default ContactForm;
