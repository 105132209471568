import React from "react";
import NavBar from "../components/common/navBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSwift, faReact } from "@fortawesome/free-brands-svg-icons";
import "./styles/skills.css";

const Mobile = () => {
	return (
		<><NavBar active = "Skills">a</NavBar><div className="skills-container">
			<h1>Mobile Skills</h1>
			<div className="skills-icons">
				<FontAwesomeIcon icon={faSwift} size="3x" className="fa-icon" />
				<FontAwesomeIcon icon={faReact} size="3x" className="fa-icon" />
			</div>
			<h2>Relavant Experience</h2>
			<div className="project">
				<div className="project-logo">
				</div>
				<h3>Yu Ride</h3>
				<p>Developed a mobile application using SwiftUI for iOS.</p>
				<p><strong>Key Contributions:</strong></p>
				<ul>
					<li>Designed and implemented user-friendly interfaces with SwiftUI.</li>
					<li>Integrated various APIs for real-time data updates and notifications.</li>
					<li>Collaborated with cross-functional teams to ensure seamless app functionality.</li>
				</ul>
				<div className="tech-list">
					<span className="tech-item">SwiftUI</span>
				</div>
			</div>
		</div></>
	);
};

export default Mobile;
