import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NavBar from "./components/common/navBar";
import Home from "./pages/homepage"
import About from "./pages/about";
import Projects from "./pages/projects";
import Contact from "./pages/Contact";
import Frontend from "./pages/Frontend";
import Backend from "./pages/Backend";
import DevOps from "./pages/DevOps";
import Mobile from "./pages/Mobile";

import "./app.css";

const App = () => {
	return (
			<div className="app">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/skills/frontend" element={<Frontend />} />
					<Route path="/skills/backend" element={<Backend />} />
					<Route path="/skills/devops" element={<DevOps />} />
					<Route path="/skills/mobile" element={<Mobile />} />
				</Routes>
			</div>
	);
};

export default App;
