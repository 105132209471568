import React from "react";
import NavBar from "../components/common/navBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAws, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import "./styles/skills.css";

const DevOps = () => {
	return (

		<><NavBar active="skills" /><div className="skills-container"></div><div className="skills-container">
			<h1>DevOps Skills</h1>
			<div className="skills-icons">
				<FontAwesomeIcon icon={faAws} size="3x" className="fa-icon" />
				<FontAwesomeIcon icon={faGoogle} size="3x" className="fa-icon" />
				<FontAwesomeIcon icon={faServer} size="3x" className="fa-icon" />
			</div>
			<h2>Relavant Experience</h2>
			<div className="project">
				<h3>SiMLQ</h3>
				<p>Worked closely with the DevOps team to streamline processes and ensure seamless integration and deployment.</p>
				<p><strong>Key Contributions:</strong></p>
				<ul>
					<li>Automated deployment processes using CI/CD pipelines.</li>
					<li>Monitored and managed cloud infrastructure on AWS and GCP.</li>
					<li>Implemented logging and monitoring solutions to ensure system reliability.</li>
				</ul>
				<div className="tech-list">
					<span className="tech-item">Azure</span>
					<span className="tech-item">Docker</span>

				</div>
			</div>
			<div className="project">
				<h3>Yu Ride</h3>
				<p>Ensured secure and efficient data management using AWS and Azure.</p>
				<p><strong>Key Contributions:</strong></p>
				<ul>
					<li>Set up and maintained cloud environments for development and production.</li>
					<li>Implemented backup and disaster recovery strategies.</li>
					<li>Ensured high availability and scalability of services.</li>
				</ul>
				<div className="tech-list">
					<span className="tech-item">AWS</span>
					<span className="tech-item">Azure</span>
					<span className="tech-item">Kubernetes</span>
				</div>
			</div>
		</div></>
	);
};

export default DevOps;
