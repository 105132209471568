import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Socials from "../components/about/socials";
import INFO from "../data/user";
import SEO from "../data/seo";
import SnakeGame from "./SnakeGame";
import "./styles/about.css";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const currentSEO = SEO.find((item) => item.page === "about");

    return (
        <React.Fragment>
            <Helmet>
                <title>{`About | ${INFO.main.title}`}</title>
                <meta name="description" content={currentSEO.description} />
                <meta name="keywords" content={currentSEO.keywords.join(", ")} />
            </Helmet>

            <div className="page-content">
                <NavBar active="about" />
                <div className="content-wrapper">
                    <div className="about-container">
                        <div className="about-main">
                            <div className="about-left-side">
                                <div className="about-image-container">
                                    <div className="about-image-wrapper">
                                        <img
                                            src="about.jpg"
                                            alt="about"
                                            className="about-image"
                                        />
                                    </div>
                                </div>
                                <div className="about-socials">
                                    <Socials />
                                </div>
                            </div>
                            <div className="about-right-side">
                                <div className="about-title">
                                    Who Am I?
                                </div>
                                <div className="about-subtitle">
                                    I am a 25-year-old coder and professional runner living in Toronto. When I’m not writing code or running, you’ll find me immersed in a podcast. I’m passionate about clean code, continuous learning, and making the most of every moment.
                                </div>
                                <div className="about-fun-facts">
                                    <h3>Fun Facts About Me</h3>
                                    <ul>
                                        <li><span className="fun-fact-icon">💻</span> I spend over 60% of my time coding, and I love every minute of it.</li>
                                        <li><span className="fun-fact-icon">🧠</span> I'm a huge fan of solving complex problems with clean and efficient code.</li>
                                        <li><span className="fun-fact-icon">📚</span> I believe in lifelong learning, always exploring new technologies and techniques.</li>
                                        <li><span className="fun-fact-icon">🎮</span> When I'm not coding, you can find me playing video games or building fun side projects.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="about-game">
                            <div className="about-h3">Take a break and play a Snake game I coded—use your arrow keys!</div>
                            <SnakeGame />
                        </div>
                    </div>
                    </div>
                </div>
        </React.Fragment>
    );
};

export default About;
