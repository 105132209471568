import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./styles/navBar.css";

const NavBar = (props) => {
	const { active } = props;
	const [menuOpen, setMenuOpen] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<div className="hamburger-icon" onClick={toggleMenu}>
							<FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
						</div>
						<ul className={`nav-list ${menuOpen ? "open" : ""}`}>
						<li className={active === "home" ? "nav-item active" : "nav-item"}>
								<Link to="/" onClick={toggleMenu}>Home</Link>
							</li>
							<li className={active === "about" ? "nav-item active" : "nav-item"}>
								<Link to="/about" onClick={toggleMenu}>About</Link>
							</li>
							<li className={active === "projects" ? "nav-item active" : "nav-item"}>
								<Link to="/projects" onClick={toggleMenu}>Experience</Link>
							</li>
							<li className={active ===     "skills" ? "nav-item active dropdown" : "nav-item dropdown"}>
								<Link to="#" onClick={toggleMenu}>Skills</Link>
								<ul className="dropdown-menu">
									<li className="dropdown-item">
										<Link to="/skills/frontend" onClick={toggleMenu}>Frontend</Link>
									</li>
									<li className="dropdown-item">
										<Link to="/skills/backend" onClick={toggleMenu}>Backend</Link>
									</li>
									<li className="dropdown-item">
										<Link to="/skills/devops" onClick={toggleMenu}>DevOps</Link>
									</li>
									<li className="dropdown-item">
										<Link to="/skills/mobile" onClick={toggleMenu}>Mobile</Link>
									</li>
								</ul>
							</li>
							<li className={active === "contact" ? "nav-item active" : "nav-item"}>
								<Link to="/contact" onClick={toggleMenu}>Contact</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
