import React from "react";
import "./styles/animatedBackground.css";

const AnimatedBackground = () => {
	return (
		<div className="animated-background">
			<div className="background-shape shape1"></div>
			<div className="background-shape shape2"></div>
			<div className="background-shape shape3"></div>
			<div className="background-shape shape4"></div>
		</div>
	);
};

export default AnimatedBackground;
