import React from "react";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
  return (
    <div className="education-section">
      <Card
        title=""
        body={
          <div className="works-body">
            <div className="work">
              <div className="work-image-container">
                <img src="yorkU.png" alt="York University" className="work-image" />
              </div>
              <div className="work-details">
                <div className="work-title">Bachelor of Information Technology</div>
                <div className="work-subtitle">York University - Toronto</div>
                <div className="work-duration">2021 - Present</div>
                <div className="work-gpa">GPA: 3.8/4.0</div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Works;
