const INFO = {
	main: {
		title: "sepehr's portfolio",
		name: "sepehr fazely",
		email: "sepifazeli@yahoo.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/sepfazeli",
		linkedin: "https://www.linkedin.com/in/sepehr-fazely/",
		instagram: "https://www.instagram.com/sepehr.fazely/",
		stackoverflow: "https://stackoverflow.com/users/26666621/sepehr-fazely",
		facebook: "https://www.facebook.com/sepehr.fazeli/",
	},

	homepage: {
		title: "Full-stack developer and mobile app developer.",
		title2: " I'm Sepehr Fazeli",
		description:
			"I am a Full Stack Software Engineer with expertise in both front-end and back-end development. I have experience in building dynamic, user-friendly interfaces using React, and developing powerful, scalable backend systems with Python and Java. Additionally, I have extensive experience in iOS development, creating robust and intuitive mobile applications using Swift, SwiftUI, and Xcode. One of the notable iOS applications I developed is a ride-sharing app during my tenure at Yu Ride, where I was responsible for both front-end and back-end development. Using SwiftUI, I created a user-friendly interface that ensured a seamless user experience. I also implemented backend functionalities to support user authentication, data storage, and real-time updates. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I live in Toronto where I enjoy improving technology and making a positive impact through innovative solutions.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	career: [
		{
			title: "SiMLQ",
			description:
				"Partnered with a process mining company and Ontario Healthcare to drive profit optimization strategies. Engineered process mining software with a React front-end and Python back-end. Led the development of new features and ensured the delivery of high-quality, reliable software solutions.",
			logo: "SiMLQ.png",
			linkText: "View website",
			link: "https://www.simlq.com",
			position: "Full Stack Software Engineer",
			date: "Jan 2024 - Present",
		},
		{
			title: "PinkDetect",
			description:
				"Led the development of PinkDetect, a healthcare solution designed to address global health challenges. Implemented machine learning algorithms for disease detection and contributed to the project's success in global health initiatives.",
			logo: "pinkdetect.png",
			linkText: "View Website",
			link: "https://solve.mit.edu/challenges/2024-global-health-challenge/solutions/88992#",
			position: "AI engineer Intern",
			date: "Feb 2023 - Jan 2024",
		},
		{
			title: "York University",
			description:
				"Conducted research on 'Mining Hybrid ML and Simulation Models from Data using Process and Queue Mining' under the supervision of Professor Arik Senderovich. The project focused on integrating machine learning algorithms with process and queue mining techniques to enhance predictive accuracy and data-driven decision-making.",
			logo:"LA&PS.png",
			linkText: "View Project",
			link: "https://www.ariksenderovich.com",
			position: "Research Assistant",
			date: "May 2024 - Sep 2024",
		},
		{
			title: "Yu Ride",
			description:
				"Worked on a start-up as a full-stack developer to build a mobile application. Contributed to front-end development using Xcode and SwiftUI. Implemented back-end functionalities with Node.js and MongoDB, ensuring secure and efficient data management.",
			logo: "YURide.png",
			linkText: "View website",
			link: "https://pitchbook.com/profiles/company/437046-49",
			position: "Application Developer Intern",
			date: "Jan 2022 - Jan 2023",
		},
		{
			title: "Lassonde Engineering",
			description:
				"Collaborated with the research team to design and refine a MongoDB database schema for a ride-sharing app. Maintained data integrity through schema design and rules.",
			logo: "Lassonde.png",
			linkText: "View Project",
			link: "https://lassonde.yorku.ca/best/",
			position: "Application Developer Intern",
			date: "Oct 2021 - Jan 2022",
		},

	],
};

export default INFO;
