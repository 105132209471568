import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<meta name="description" content={currentSEO.description} />
				<meta name="keywords" content={currentSEO.keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">

						<div className="projects-list">
							<AllProjects />
						</div>
						<h3 className="experience-heading">Side Projects</h3>

						<div className="homepage-projects-container">

							<div className="project-card project-card-blue">
								<div className="project-title">Finger Spelling</div>
								<div className="project-description">
									You developed the "Fingerspelling-iOS" app using Swift and SwiftUI, leveraging CoreML for machine learning to recognize and translate fingerspelling gestures.
								</div>
								<div className="project-links">
									<a
										href="https://github.com/sepfazeli/Fingerspelling-iOS"
										target="_blank"
										rel="noreferrer"
										className="project-link"
									>
										GitHub Repository
									</a>
								</div>
							</div>

							<div className="project-card project-card-yellow">
								<div className="project-title">React Native Vision Camera</div>
								<div className="project-description">
									Developed a mobile application using React Native that leverages Vision Camera to provide real-time image and video processing capabilities.
								</div>
								<div className="project-links">
									<a
										href="https://github.com/sepfazeli/react-native-vision-camera"
										target="_blank"
										rel="noreferrer"
										className="project-link"
									>
										GitHub Repository
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
		</React.Fragment>
	);
};

export default Projects;
